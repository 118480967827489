import "styles/pages/kalaallisut.scss"

import React from "react"
import { graphql } from "gatsby"

import Seo from "components/seo"
import Layout from "components/layout"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const KalaallisutPage = ({ data }) => {
  const title = "Kalaallisut"

  const products = data?.wpPage?.acfKalaallisut?.content
  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} color="#fff" />
      <div
        className="text-page-hero text-page-hero--kalaallisut"
        style={{
          backgroundImage: `url(${
            require("assets/images/kalaallisut/hero-bg.png").default
          })`,
        }}
      >
        <img
          src={require("assets/images/kalaallisut/hero-text.png").default}
          alt=""
        />
      </div>
      <section className="kalaallisut">
        <div className="container">
          <div
            className="kalaallisut__border-text"
            dangerouslySetInnerHTML={{
              __html: data?.wpPage?.content,
            }}
          />
          <div className="kalaallisut__list">
            {products?.map(({ image, data, color }, index) => (
              <div key={index} className="kalaallisut__list__item">
                <div className="kalaallisut__list__item__image">
                  <img src={image?.localFile?.publicURL} alt="" />
                </div>
                <div className="kalaallisut__list__item__info">
                  {data?.map(
                    ({ header, description, listHeader, listItems }, index) => (
                      <>
                        <h3 style={{ color: color }}>{header}</h3>
                        <p>{description}</p>
                        <div className="kalaallisut__list__item__info__list">
                          <span>{listHeader}</span>
                          <ul>
                            {listItems?.map((item, index) => (
                              <li key={index}>{item?.item}</li>
                            ))}
                          </ul>
                        </div>
                        {index === 0 && (
                          <div className="kalaallisut__list__item__info__separator"></div>
                        )}
                      </>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default KalaallisutPage

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoyMTcx" }) {
      content
      acfKalaallisut {
        content {
          color
          image {
            localFile {
              publicURL
            }
          }
          data {
            description
            header
            listHeader
            listItems {
              item
            }
          }
        }
      }
    }
  }
`
